@import "fonts";
@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/toastr/toastr";
@import "node_modules/bootstrap-daterangepicker/daterangepicker.scss";

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

body
{
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch
}

//#page-content {
//    overflow: hidden;
//}

#page-content .container-fluid {
    width: 975px;
    margin: 0;
}

#page-content .container-fluid.long-table {
    width: 1400px;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin: 0;
    color: #666666;
    font-weight: 400;
    letter-spacing: 0.3px;
}

h1
{
    margin-bottom: 16px;
    font-size: 48px;
}

h2
{
    margin-bottom: 14px;
    font-size: 38px;
}

h3
{
    margin-bottom: 12px;
    font-size: 32px;
}

h4
{
    margin-bottom: 10px;
    font-size: 24px;
}

h5
{
    margin-bottom: 8px;
    font-size: 18px;
}

h6
{
    margin-bottom: 6px;
    font-size: 16px;
}

a {
    color: #00b9e5;
}

a:hover,
a:focus {
    outline: 0;
    color: #00b9e5;
}

/* Flex */
.is-flex
{
    display: flex;
}

.is-flex-1
{
    flex: 1;
}

.text-success
{
    color: #4dc07e;
}

.text-warning
{
    color: #f0b654;
}

.text-danger
{
    color: #e9331f;
}

.text-info
{
    color: #00b9e5;
}

.text-green
{
    color: #43c021;
}

.text-info a
{
    color: #00b9e5;
}

.mt-140 {
    margin-top: 140px;
}

.has-error .help-block
{
    font-size: 10px;
    color: #ea3b28;
}

.has-error .form-control
{
    border-color: #ea3b28;
}

.has-error .form-control:focus
{
    border-color: #ea3b28;
    box-shadow: none;
}

.has-error .form-control + .help-block
{
    margin-top: -10px;
}

.alert
{
    display: inline-block;
    padding: 10px 15px;
}

.alert-success
{
    border-color: #efffe6;
    background-color: #efffe6;
    color: #4cc138;
}

.alert-danger
{
    border-color: #f1dede;
    background-color: #f1dede;
    color: #e63a2f;
}

.divider {
    margin: 45px 0;
    border-top: 1px solid #d8d8d8;
}

.form-control,
textarea,
input[type="text"],
input[type="email"],
input[type="password"]
{
    height: 36px;
    padding: 7px 12px;
    border-color: #ddd;
    border-radius: 2px;
    box-shadow: none;
    margin-bottom: 15px;
}

label
{
    position: relative;
    margin-bottom: 5px;
    font-weight: 400;
    //letter-spacing: 0.4px;
}

.form-control
{
    height: 36px;
}

.form-control:focus,
textarea:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus
{
    border-color: #bbb;
    box-shadow: none;
    outline: 0;
}

select.form-control,
select
{
    border-color: #ddd;
    border-radius: 2px;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/images/arrow.png) no-repeat center right;
}

#concept,
#store_text,
#territory {
    display: inline-block;
    width: auto;
    padding-right: 25px;
    background-color: #fff;
    text-align: left;
    background-image: url(/images/arrow-2.png);
    color: #9e9e9e;
}

#store_text {
    background-image: none;
    padding-right:45px;
}

input[type="checkbox"] {
    position: absolute;
    left: -99999px;
}

input[type="checkbox"] + span {
    position: relative;
    display: inline-block;
    top: 5px;
    width: 24px;
    height: 22px;
    margin-right: 5px;
    background: url(/images/check.png) no-repeat top center;
}

input[type="checkbox"]:checked + span {
    background-position: bottom center;
}

::-moz-placeholder {
    color: #cccccc !important;
}

::-webkit-input-placeholder {
    color: #cccccc !important;
}

:-ms-input-placeholder {
    color: #cccccc !important;
}

#search::-moz-placeholder {
    color: #9f9f9f !important;
}

#search::-webkit-input-placeholder {
    color: #9f9f9f !important;
}

#search:-ms-input-placeholder {
    color: #9f9f9f !important;
}

.btn-success[disabled]:focus,
.btn-success[disabled]:hover {
    border-color: #00b9e5;
    background-color: #00b9e5;
}

.btn
{
    border-radius: 3px;
}

.panel.panel-default
{
    padding: 20px;
    border: none;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: none;
}

.panel {
    border-radius: 0;
    box-shadow: none;
}

.panel-body {
    padding: 10px;
}

.hidden-buttons
{
    position: absolute;
    z-index: 100;
    top: 0;
    display: none;
    width: 100%;
    margin-left: -2px;
}

.hidden-buttons .btn-xs {
    width: 56px;
    height: 30px;
    padding-right: 0;
    padding-left: 0;
    font-size: 10px;
}

.btn
{
    border-radius: 2px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.btn-xs
{
    padding: 5px 16px;
    font-size: 12px;
}

.btn-print
{
    padding: 7px 16px;
    line-height: 14px;
}

.btn-lg
{
    padding: 12px 35px;
    font-size: 18px;
}

.btn-lg + .btn-lg {
    margin-left: 20px;
}

.show-order-details, .small-button
{
    padding: 7px 8px;
    font-size: 9px;
    line-height: 14px;
}

.btn-success
{
    border-color: #00b9e5;
    background-color: #00b9e5;

    &:hover,
    &:focus,
    &:active:focus
    {
        border-color: #02a1c7;
        outline: none;
        background-color: #02a1c7;
        box-shadow: none;
    }
}

.btn-grey
{
    border-color: #999999;
    background-color: #999999;
    color: #fff;

    &:hover,
    &:focus,
    &:active:focus
    {
        border-color: #777777;
        background-color: #777777;
        color: #fff;
    }
}

.btn-green
{
    border-color: #43c021;
    background-color: #43c021;
    color: #fff;

    &:hover,
    &:focus,
    &:active:focus
    {
        border-color: #3b9822;
        background-color: #3b9822;
        color: #fff;
    }
}

.input-group-addon
{
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    line-height: inherit;
    text-align: left;
}

#search + .input-group-addon .btn
{
    padding: 8px 12px;
}

#search + .input-group-addon input.btn {
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.login-section
{
    margin-top: 150px;

    h4
    {
        margin-bottom: 5px;
        font-size: 26px;
    }

    p.text-muted {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .panel-default
    {
        width: 380px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 35px;
        border: none;
        margin-right: auto;
        margin-left: auto;
        background-color: #f5f5f5;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

        .panel-heading
        {
            padding-right: 0;
            padding-left: 0;
            border-bottom: none;
            text-align: center;
            color: #666666;

            &.reset {
                padding-right: 15px;
                padding-left: 15px;

                p {
                    margin-top: 20px;
                    margin-bottom: 0;
                }

                ul {
                    padding-left: 0;
                    list-style: none;

                    li {
                        position: relative;

                        &:before {
                            position: relative;
                            top: -2px;
                            display: inline-block;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            margin-right: 7px;
                            background-color: #666666;
                            content: '';
                        }
                    }
                }
            }
        }

        .form-group
        {
            margin-bottom: 0;
        }
    }

    button[type="submit"]
    {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 1px;
        padding: 7px 12px;
        font-weight: 700;
    }

    label {
        margin-bottom: 2px;
    }

    .resend-message
    {
        padding: 0 0 20px 0;
    }
}

/**
Header
 */
#header
{
    background: $headerBackground;
    border-bottom: solid 1px #ddd;
    padding: 30px 0;
}

.font-size-normal
{
    font-size: 1em !important;
}

.table
{
    &.table-condensed
    {
        margin-bottom: 0;
    }
}

#header
{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: -1px;
    width: 220px;
    background-color: #242428;
    color: #fff;
    letter-spacing: .2px;

    .container-fluid
    {
        padding: 0;
    }

    .nav-pills
    {

        margin-top: 16px;
        font-family: $font-family-nav;

        ul
        {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            li
            {
                a
                {
                    padding-left: 55px;
                    font-size: 13px;
                }
            }
        }

        li
        {
            float: none;
            margin-left: 0;

            a
            {
                display: block;
                padding: 15px 10px;
                border-left: 4px solid transparent;
                border-radius: 0;
                color: #fff;
                -webkit-transition: all 0.25s;
                transition: all 0.25s;

                &:focus,
                &:hover
                {
                    text-decoration: none;
                    background-color: #333337;
                    color: #fff;
                }

                img
                {
                    margin-right: 7px;
                }

                span
                {
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                    margin-left: 3px;
                    background-color: #f7435d;
                    color: #fff;
                    font-size: 12px;
                    line-height: 19px;
                    text-align: center;
                }
            }

            &.active
            {
                a
                {
                    border-left-color: #00b9e5;
                    background-color: #333337;
                }
            }
        }
    }
    .logo
    {
        max-width: 150px;
    }
}

.nav-pills > li:last-child > a
{
    border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.nav-pills > li:first-child > a {
    border-top: none;
}

.user-info
{
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 0 30px;
    font-size: 12px;
}

.user-info a
{
    color: #fff;
}

#header + #page-content
{
    padding: 40px 40px 40px 245px;
}

.headline
{
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.container-fluid:first-of-type .headline:first-child,
.container-fluid:first-child .headline:first-child {
    border-bottom: none;
}

.container-fluid:first-of-type .headline:first-child:after,
.container-fluid:first-child .headline:first-child:after {
    display: block;
    min-width: 976px;
    width: calc(100vw - 70px);
    border-bottom: 1px solid #ddd;
    margin-top: 15px;
    content: '';
}

.container-fluid:first-of-type .headline.returns-page:first-child:after,
.container-fluid:first-child .headline.returns-page:first-child:after {
    width: calc(100vw - 277px);
}

_:-webkit-full-screen:not(:root:root), .container-fluid:first-of-type .headline.collection-page:first-child:after,
_:-webkit-full-screen:not(:root:root), .container-fluid:first-child .headline.collection-page:first-child:after {
    width: calc(100vw - 70px);
}

.container-fluid.long-table:first-child .headline:first-child:after {
    min-width: 1100px;
}

a + .headline
{
    margin-top: 15px;
}

.table + .headline
{
    margin-top: 35px;
}

.headline h4,
.headline h5,
.headline h6
{
    margin: 0;
}

.widgets
{
    padding: 0;
    margin: 0 0 35px 0;
    color: #333333;
    font-size: 12px;
    line-height: 14px;
    list-style: none;
}

.widgets li
{
    float: left;
    width: 180px;
    padding: 15px 15px 8px;
    border: solid 1px rgba(221, 221, 221, 0.6);
    border-radius: 2px;
    margin-right: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.widgets li .number
{
    display: block;
    margin-top: 14px;
    color: #535353;
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
}

.widgets li:last-child
{
    margin-right: 0;
}

.widgets li.danger,
.widgets li.danger .number
{
    color: #e9331f;
}

.widgets li.success,
.widgets li.success .number
{
    color: #4ec42e;
}

.widgets:after
{
    display: table;
    clear: both;
    content: '';
}

/* PAGINATION */
.pagination {
    font-size: 12px;
}

.pagination > li > a,
.pagination > li > span {
    color: #535353;
    padding: 13px 15px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.pagination > li > a,
.pagination > li > a:focus,
.pagination > li > span {
    color: #535353;
}

.pagination > li > a:hover,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > a:focus {
    border-color: #ddd;
    background-color: #f0f2f3;
    color: #535353;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: 3px;
}

.pagination > li a > span {
    display: inline-block;
    min-width: 45px;
}

#order-details-modal
{

}

.modal-dialog
{
    width: 465px;
    margin-top: 115px;
}

#order-details-modal, #searched-order-details-modal
{
    .modal-dialog
    {
        width: 500px;
        height: 545px;
        background-color: #fff;
        overflow-y: hidden;

        .order-items
        {
            height: 480px;
            overflow: auto;
        }

        .modal-content
        {
            box-shadow: none;
        }
    }
}

.modal-content
{
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.modal-header
{
    border-bottom: 1px solid #e7eaec;
    text-align: center;
}

.modal-header .close
{
    margin-top: -10px;
    margin-right: -5px;
    font-size: 32px;
    font-weight: 400;
}

.modal-body
{
    padding: 15px 80px 0;
}

#order-details-modal, #searched-order-details-modal
{
    .modal-body
    {
        padding: 0;
        .media
        {
            padding: 10px;
            border-bottom: 1px solid #e7eaec;
            margin: 0;
            &:last-child
            {
                border-bottom: none;
            }
        }
    }
}

.modal-footer
{
    padding: 5px 80px 40px;
    border-top: none;
    text-align: center;
}

.modal-footer .btn
{
    width: 48%;
}

.choose-box {
    font-size: 20px;
    text-align: center;
}

.choose-box:first-of-type {
    padding-right: 25px;
}

.choose-box:last-of-type {
    padding-left: 25px;
}

.choose-box img {
    margin-right: 6px;
}

.choose-box a {
    display: block;
    padding: 30px;
    border-radius: 2px;
    border: 2px solid #dddddd;
    color: #666;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.25s;
            transition: all 0.25s;
}

.choose-box a:hover {
    background-color: #edeff1;
    text-decoration: none;
}

body > .logout {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
}

body > .logout a {
    color: #666;
}

input#select_all {
    margin-left: -18px;
}

.media .media-left {
    max-width: 200px;
    padding-right: 20px;
}

form > label {
    font-weight: 700;
}

form .media {
    position: relative;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7eaec;
    margin-bottom: 15px;
}

form .media:first-of-type {
    padding-top: 15px;
    border-top: 1px solid #e7eaec;
}

form .media-left {
    padding-left: 40px;
}

form .media-left label {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.media select {
    width: 60px;
    height: 36px;
    padding: 6px 10px;
    border: 1px solid #e7eaec;
    margin-left: 5px;
    background: #fff url(/images/arrow.png) no-repeat center right;
    text-align: left;
}

.media select:disabled {
    border: 1px solid #e7eaec;
    background-image: none;
    text-align: center;
}

.media ul li p {
    display: inline-block;
}

.media ul li p + select
{
    display: inline-block;
    width: 50%;
}

.modal-footer .btn
{
    width: 48%;
}

/*
 * MaxLength
 */
.characters-remaining
{
    //right: 0 !important;
}

.inactiveLink
{
    pointer-events: none;
    cursor: default;
}

.return-detail
{
    display: inline-block;
    margin-right: 20px;
}

.counter
{
    color: #999;
    font-size: 12px;
    margin-top: -10px;
}

/*
 * Move & put-away
 */
.text-updated
{
    position: absolute;
    top: 50%;
    left: 150px;
    -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
}

@media (max-width: 992px) {

    #header + #page-content {
        padding: 40px 20px 40px 240px;
    }

    .media ul li p + select {
        width: 90%;
    }

    #collector-information-modal {
        width: 768px;
    }

}

/***********************************************************************************
 *	+ TABLES
 ***********************************************************************************/

.table
{
    border-collapse: inherit;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 51px;
    width: 945px;
}

.long-table .table {
    width: 1400px;
}

.table > thead > tr > th
{
    padding: 5px 10px;
    border-bottom: none;
    background-color: #dbe2e6;
    font-size: 14px;
    vertical-align: middle;
}

.table > thead .table {
    font-size: 14px;
}

.table > thead .table td {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
}

.table > thead > tr > th a.sortcolumn {
    position: relative;
    display: inline-block;
    padding: 7px 15px 7px 5px;
    margin-left: -7px;
    color: #535353;
}

.table > thead > tr > th a.sortcolumn:hover {
    background-color: #fff;
    text-decoration: none;
}

.table > thead > tr > th a.sortcolumn:after {
    position: absolute;
    top: 50%;
    right: 5px;
    display: block;
    width: 7px;
    height: 7px;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin-top: -2px;
    content: "";
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
}

.table > thead > tr > th a.sortcolumn.desc:after {
    border-color: #535353;
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
}

.table > thead > tr > th a.sortcolumn.asc:after {
    border-color: #535353;
    margin-right: -4px;
    -webkit-transform: rotate(225deg) translateY(-50%);
    -ms-transform: rotate(225deg) translateY(-50%);
    transform: rotate(225deg) translateY(-50%);
}

.table > tbody > tr
{
    background-color: #fff;
}

.table > tbody > tr td
{
    position: relative;
    padding: 16px 10px;
    vertical-align: middle;
}



.table > tbody > tr td .table td {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: top;
}

.table > tbody > tr td.no-padding
{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.table > tbody > tr:nth-child(2n)
{
    background-color: #f0f2f3;
}

.table .table > tbody > tr
{
    background-color: transparent;
}

.table .table > tbody > tr:nth-child(2n),
.table .table
{
    background-color: transparent;
}

.table > tbody > tr + tr
{
    border-top: 1px solid #ddd;
}

.table .table > tbody > tr .input-group-btn > .btn,
.table .table > tbody > tr .hidden-buttons
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table .table > tbody > tr .input-group-addon,
.table .table > tbody > tr .input-group-btn {}


.table .table
{
    border: none;
    margin-bottom: 0;
    min-width: 0;
    width: 100%;
}

.table .table tr td
{
    border-top: 1px solid #ddd;
}

.table > tbody > tr:first-child > td
{
    border-top: none;
}

.table .table tr td input
{
    width: 80px;
    height: 30px;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.table .table tr td input + .input-group-btn
{
    display: inline-block;
}

@supports (-ms-ime-align:auto) {}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {}

/* Dashboard Table */
.dashboard-table th,
.dashboard-table td {
    width: 16.66666666667%;
}

.dashboard-table th.warehouse,
.dashboard-table td.warehouse {
    min-width: 170px;
    max-width: 170px;
}

.dashboard-table.table > thead > tr th {
    padding: 8px 30px;
}

.dashboard-table.table > tbody > tr td {
    padding: 16px 30px;
}

#filter
{
    .form-control
    {
        margin-bottom: 0;
    }
    h6
    {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    #date
    {
        background: #fff;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #ccc;
        width: 100%;
        margin-right: 10px;
        color: #9e9e9e;
    }
}

/* Receive & Put-away Table */
.receive-table .order {
    max-width: 100px;
    min-width: 100px;
    word-break: break-word;
}

.receive-table .date-type {
    max-width: 112px;
    min-width: 112px;
    width: 112px;
}

.receive-table .date-rec {
    max-width: 110px;
    min-width: 110px;
}

.receive-table .name {
    max-width: 130px;
    min-width: 130px;
    width:130px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.receive-table .phone {
    max-width: 115px;
    min-width: 115px;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
}

.receive-table .consignment {
    max-width: 165px;
    min-width: 165px;
    word-break: break-all;
    width: 165px;
    //padding-right: 5px !important;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
}

.receive-table .status,
.receive-table .parcels {
    max-width: 100px;
    min-width: 100px;
    text-align: left;
    width: 100px;
}

.receive-table .status.status-shipped {
    color: #e88810;
}

.receive-table .status.status-received {
    color: #34b05f;
}

.receive-table .buttons {
    max-width: 85px;
    min-width: 85px;
}

.receive-table .location {
    max-width: 205px;
    min-width: 205px;
    width:205px;
}

/* Uncollected Table */
#data_table_uncollected .location {
    max-width: 180px;
    min-width: 180px;
    word-wrap: break-word;
}

#data_table_uncollected tr td{
    vertical-align: middle
}

/* Collected Table */
#data_table_collected td {
    vertical-align: middle;
}

#data_table_collected .date-rec {
    max-width: 117px;
    min-width: 117px;
}

/* Stale Orders Table */
#data_table_stale_orders .location {
    max-width: 130px;
    min-width: 130px;
    word-wrap: break-word;
}

#data_table_stale_orders .consignment {
    max-width: 165px;
    min-width: 165px;
    word-wrap: break-word;
}

#data_table_stale_orders > thead > tr td.consignment {
    max-width: 165px;
    max-width: 165px;
    word-wrap: break-word;
}

#data_table_stale_orders .phone {
    max-width: 110px;
    min-width: 110px;
}

#data_table_stale_orders .return {
    max-width: 120px;
    min-width: 120px;
}
#data_table_stale_orders .return span{
    float:left;
}
table#data_table_stale_orders {
    width: 1100px;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .user-info{
        top: 10vh
    }
}

.table > tbody > tr td{
    vertical-align: top !important;
}

.consignment_table > tbody > tr td{
    padding-top: 16px !important;
    padding-bottom: 5px !important;
}
#data_table_stale_orders .comment-section{
    min-width: 200px;
    max-width: 200px;
}
#data_table_stale_orders .comment-section span{
    float: left;
}
#data_table_stale_orders .return div{
    float: right;
}

#data_table_stale_orders .consignment-days{
    min-width: 70px;
    max-width: 70px;
}

#data_table_stale_orders .hidden-buttons{
    margin-left: -78px;
}

/* Uncollected Table */
#data_table_uncollected .reason {
    max-width: 300px;
    min-width: 300px;
    width: 300px;
    word-wrap: break-word;
}
.consignment_table_header > tbody > tr td{
    vertical-align: middle !important;
}
.widgets li a{
 color: #000;
 text-decoration: none;
}
.user-info p{
    line-height: 12px;
    margin: 0 0 8px;
}

.receive-table .c_phone {
    max-width: 115px;
    min-width: 115px;
    width: 115px;
    word-wrap:break-word;
}
.receive-table .hybris_status{
    max-width: 190px;
    min-width: 190px;
}
.receive-table .hybris_status div{
    float: left;
}
.receive-table .hybris_status .loader {
    display: none;
    margin: 7px 0 0 5px;
}
.receive-table .hybris_status #status_div {
    width: 134px;
    text-align: center;
}

.receive-table .hybris_status .status_section {
    float:left;
    width: 140px;
}

.receive-table .retrieve_act{
    max-width: 116px;
    min-width: 116px;
}
.receive-table .retrieve_act div{
    float: left;
}
.receive-table .retrieve_act .loader {
    display: none;
    margin: 7px 0 0 5px;
}
.widgets li.active {
    background-color: #CCC;
}

.receive-table .order_status {
    max-width: 168px;
    min-width: 168px;
}

.filter-section {
    padding-bottom: 14px;
}

.typeahead {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

#search_btn {
    height: 36px;
}

.hybris-error{
    color: #ff0000 !important;
}

.receive-table .status{
    max-width: 150px;
    min-width: 150px;
}
.receive-table .collector_id_type {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}
.receive-table .collector_id {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    word-break: break-all;
}
.widgets li:first-child .number{
    margin-top: 28px;
}
.container-fluid:first-of-type .headline.collection-page:first-child:after {
    width: calc(100vw - 72px);
}
#page-content .container-fluid.long-table.uncollectpage {
    width: 1516px;
}
.container-fluid.uncollectpage:first-of-type .headline:first-child:after {
    width: calc(104vw - 12px);
}
#data_table_uncollected{
    width: 1500px;
}
.container-fluid:first-of-type .headline.dashboard:first-child:after{
    width: unset;
}

@media (min-width: 768px) and (max-width: 1024px) {

    #page-content .container-fluid.long-table {
        width: 1528px !important;
    }
    .container-fluid.long-table:first-child .headline:first-child:after{
        min-width: 1498px !important;
        width: 1498px !important;
    }

    .container-fluid:first-of-type .headline.uncollect:first-child:after {
        min-width: 1492px !important;
        width: 1492px !important;
    }

    .long-table .table {
        width: 1020px !important;
    }

    .putaway-table .table {
        width: 1134px !important;
    }

    #page-content .container-fluid.long-table.uncollectpage{
        width: 1522px !important;
    }

    .view_details{
        text-align: left !important;
    }

    .receive-table .retrieve_act{
        max-width: 80px !important;
        min-width: 80px !important;
    }
}

.green-disabled{
    background-color: #ccfbbf !important;
    border-color: #ccfbbf !important;
    cursor: unset;
    color: #FFFFFF !important;
}

.blue-disabled{
    background-color: #d6eafb;
    border-color: aliceblue;
    cursor: unset;
}