// Fancybox
$fancybox-image-url: "/images";
// App fonts
$font-family: 'Lato', sans-serif;
$font-family-nav: 'LatoWeb', sans-serif;
// FontAwesome
$fa-font-path: "../../fonts/" !default;
// Glyphicons
$icon-font-path: "../../fonts/bootstrap/";

$headerBackground: #efefef;

$bootstrap-sass-asset-helper: false !default;