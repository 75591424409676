/* Webfont: Lato-Regular */@font-face {
    font-family: 'LatoWeb';
    src: url('/fonts/lato/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}